import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';

export const environment: IEnvironment = {
  production: false,
  serverBaseUri: 'https://wallex-bitgenera.dev2.scrij.com',
  wsUrl: 'https://wallex-bitgenera.dev2.scrij.com',
  intercomId: 'hkgmx1f6',
  reduxStoreFreeze: true,
  reduxDevTools: true,
  reCaptchaSiteKey: '6Le8FHEoAAAAAA4JviyIaO8vw40fISGBji399oHe',
  isReferralProgramEnabled: true,
  isFireblocksKeyGenerationEnabled: true,
  appLink: 'https://custody.bitgenera.io/',
  isAuthBanner: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBvApbkuwBvu50dpfH8iGiiRwUQowq03zI',
    authDomain: 'neobanking-wallex.firebaseapp.com',
    projectId: 'neobanking-wallex',
    storageBucket: 'neobanking-wallex.appspot.com',
    messagingSenderId: '913232021732',
    appId: '1:913232021732:web:71a1f1973b203f909f44f1',
    measurementId: 'G-BG35JJKRWS'
  },
  vapidKey: 'BJ_cIVpePs5IijyYGQcpITfGXrvgGf5HqLraG2UOLZh__QVM0773kdb4DOm7uBArRbMyH78zrlh0K1kU8P3zhiA',
  backgroundNotificationChannel: 'background-notification-channel-bitgenera',
  backgroundNotificationClickChannel: 'background-notification-click-channel-bitgenera',
  sentryDsn: 'https://ba277c6f0517fd45658cc48871e7a3a1@sentry.agilie.com/5'
};
